/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonTable {
    classItem = 'button-table--item'
    classButton = 'button-table--button'
    classItemView = 'table-price__items---view'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let textTo = $(this).data('to')
            let textDo = $(this).data('do')

            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemView)

            if ($(this).closest(`.${self.classItem}`).hasClass(self.classItemView)) {
                $(this).text(textDo)
            } else {
                $(this).text(textTo)
            }
        })
    }
}
